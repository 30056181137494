import { combineReducers } from 'redux';
import Auth from '../authentication/reducer';
import App from '@lepass/redux/app/reducer';
import Mails from '@lepass/redux/mail/reducer';
import Calendar from '@lepass/redux/calendar/reducer';
import Box from '@lepass/redux/box/reducer';
import Notes from '@lepass/redux/notes/reducer';
import Todos from '@lepass/redux/todos/reducer';
import Contacts from '@lepass/redux/contacts/reducer';
import Cards from '@lepass/redux/card/reducer';
import Chat from '@lepass/redux/chat/reducers';
import DynamicChartComponent from '@lepass/redux/dynamicEchart/reducer';
import Ecommerce from '@lepass/redux/ecommerce/reducer';
import ThemeSwitcher from '@lepass/redux/themeSwitcher/reducer';
import Invoices from '@lepass/redux/invoice/reducer';
import LanguageSwitcher from '@lepass/redux/languageSwitcher/reducer';
import YoutubeSearch from '@lepass/redux/youtubeSearch/reducers';
import Articles from '@lepass/redux/articles/reducers';
import Investors from '@lepass/redux/investors/reducers';
import Categories from '@lepass/redux/crud/categories/reducers';
import SubCategories from '@lepass/redux/crud/subCategories/reducers';
import Editions from '@lepass/redux/crud/editions/reducers';
import Highlights from '@lepass/redux/crud/highlights/reducers';
import Offers from '@lepass/redux/crud/offers/reducers';
import Orders from '@lepass/redux/crud/orders/reducers';
import Partners from '@lepass/redux/crud/partners/reducers';
import Shops from '@lepass/redux/crud/shops/reducers';
import Users from '@lepass/redux/crud/users/reducers';
import scrumBoard from '@lepass/redux/scrumBoard/reducer';
import drawer from '@lepass/redux/drawer/reducer';
import modal from '@lepass/redux/modal/reducer';
import quiz from '@lepass/redux/quiz/reducer';
import profile from '@lepass/redux/profile/reducer';
import githubSearch from '@lepass/redux/githubSearch/reducers';
import Discounts from '@lepass/redux/crud/discounts/reducers';
import Qrcodes from '@lepass/redux/crud/qrcodes/reducers';
import CouponCampaigns from '@lepass/redux/crud/couponCampaigns/reducers';
import Thematics from '@lepass/redux/crud/thematics/reducers';
import Coupons from '@lepass/redux/crud/coupons/reducers';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Mails,
  Calendar,
  Box,
  Notes,
  Todos,
  Contacts,
  Cards,
  Chat,
  DynamicChartComponent,
  Ecommerce,
  Invoices,
  YoutubeSearch,
  Articles,
  Investors,
  Categories,
  SubCategories,
  Editions,
  Highlights,
  Offers,
  Orders,
  Partners,
  Shops,
  Users,
  scrumBoard,
  modal,
  quiz,
  drawer,
  profile,
  githubSearch,
  Discounts,
  Qrcodes,
  CouponCampaigns,
  Thematics,
  Coupons,
});
