import { notification } from '@lepass/components/index';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import FirebaseHelper from './FirebaseAuth';
import authAction from '@lepass/auth/actions'

const { logout } = authAction;

function* loginRequest({ payload: { user, password } }) {
  try {
    const auth = yield FirebaseHelper.login('email', {email: user, password});
    if (!auth.error) {
      const token = yield FirebaseHelper.getToken();
      const response = yield fetch('/api/login', {
        headers: new Headers({Authorization: token})
      });
      if (response.status !== 200) {
        const body = yield response.json();
        throw new Error(body.error);
      }
    } else {
      throw new Error(auth.error.message);
    }
  } catch (error) {
    notification('error', error.message);
    yield FirebaseHelper.logout();
    yield put(actions.loginRequestFailure(error));
  }
}

function* logoutRequest(signout) {
  try {
    yield signout();
    yield put(actions.logoutRequestSuccess());
  } catch (error) {
    yield put(actions.logoutRequestFailure(error));
  }
}

export function* onLogin() {
  yield takeLatest(actions.LOGIN_REQUEST_START, loginRequest);
}
export function* onLogout() {
  yield takeLatest(actions.LOGOUT_REQUEST_START, logoutRequest);
}
export default function* rootSaga() {
  yield all([call(onLogin), call(onLogout)]);
}
