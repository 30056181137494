import { all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import CollectionSagas from '../Collection/sagas';

class Offers extends CollectionSagas {

    constructor() {
        super(actions,'offers', ['editions', 'partner'], 'createdAt', 'desc');
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_FIRESTORE, new Offers().loadFromFirestore),
        takeEvery(actions.SAVE_INTO_FIRESTORE, new Offers().storeIntoFirestore),
    ]);
}
