import actions from './actions';

const initState = {
  token: null,
  user: null,
  error: null,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST_SUCCESS:
      return { ...state, ...action.payload };
    case actions.LOGOUT_REQUEST_FAILURE:
    case actions.LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        token: null,
        user: null,
        error: action.payload,
      };
    case actions.LOGOUT_REQUEST_SUCCESS:
      return initState;
    default:
      return state;
  }
}
