export const variables = {
  WHITE_COLOR: '#ffffff',
  LIGHT_GRAY_COLOR: '#efefef',
  PRIMARY_COLOR: '#1f2431',
  SECONDARY_COLOR: '#9aa1b0',
  HEARDER_COLOR: '#788195',
  PRIMARY_BG_COLOR: '#f8f9fe',
  SECONDARY_BG_COLOR: '',
  BORDER_COLOR: '',
  PRIMARY_BUTTON_COLOR: process.env.NEXT_PRIMARY_COLOR ?? "#dc0c1f",
  PRIMARY_COLOR_PASS: process.env.NEXT_PRIMARY_COLOR ?? "#dc0c1f",
  SECONDARY_BUTTON_COLOR: '',
  TOPBAR_HEIGHT: '70px',
  PRIMARY_BUTTON_HEIGHT: '30px',
  LINE_HEIGHT: '30px',
  PRIMARY_BOX_SHADOW: '30px',
  HEADER_FONT_SIZE: '19px',
  PRIMARY_FONT_FAMILY: 'Roboto',
  FONT_WEIGHT: '500',
};
