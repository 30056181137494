import { all } from 'redux-saga/effects';
import authSagas from '../authentication/sagas';
import contactSagas from '@lepass/redux/contacts/saga';
import invoicesSagas from '@lepass/redux/invoice/saga';
import mailSagas from '@lepass/redux/mail/saga';
import notesSagas from '@lepass/redux/notes/saga';
import todosSagas from '@lepass/redux/todos/saga';
import ecommerceSaga from '@lepass/redux/ecommerce/saga';
import cardsSagas from '@lepass/redux/card/saga';
import chatSagas from '@lepass/redux/chat/sagas';
import youtubeSearchSagas from '@lepass/redux/youtubeSearch/sagas';
import githubSagas from '@lepass/redux/githubSearch/sagas';
import articles from '@lepass/redux/articles/sagas';
import investors from '@lepass/redux/investors/sagas';
import categories from '@lepass/redux/crud/categories/sagas';
import subCategories from '@lepass/redux/crud/subCategories/sagas';
import editions from '@lepass/redux/crud/editions/sagas';
import highlights from '@lepass/redux/crud/highlights/sagas';
import offers from '@lepass/redux/crud/offers/sagas';
import orders from '@lepass/redux/crud/orders/sagas';
import partners from '@lepass/redux/crud/partners/sagas';
import shops from '@lepass/redux/crud/shops/sagas';
import users from '@lepass/redux/crud/users/sagas';
import discounts from '@lepass/redux/crud/discounts/sagas';
import scrumBoardSaga from '@lepass/redux/scrumBoard/saga';
import quizSaga from '@lepass/redux/quiz/saga';

import profileSaga from '@lepass/redux/profile/saga';
import qrcodes from '@lepass/redux/crud/qrcodes/sagas';
import couponCampaigns from '@lepass/redux/crud/couponCampaigns/sagas';
import thematics from '@lepass/redux/crud/thematics/sagas';
import coupons from '@lepass/redux/crud/coupons/sagas';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    contactSagas(),
    mailSagas(),
    notesSagas(),
    todosSagas(),
    ecommerceSaga(),
    cardsSagas(),
    invoicesSagas(),
    chatSagas(),
    youtubeSearchSagas(),
    articles(),
    investors(),
    categories(),
    subCategories(),
    editions(),
    highlights(),
    offers(),
    orders(),
    partners(),
    shops(),
    users(),
    discounts(),
    scrumBoardSaga(),
    quizSaga(),
    profileSaga(),
    githubSagas(),
    qrcodes(),
    couponCampaigns(),
    thematics(),
    coupons(),
  ]);
}
